<template>
  <div v-if="devicefind.sf != null && datasource.ig.length > 0">
    <div class="card igcard">
      <div class="card-header d-flex justify-content-start p-1">
        <h5 class="mb-0 pb-0 text-uppercase text-white">Bizi Takip Edin!</h5>
        <a
        target="_blank"
          href="https://www.instagram.com/tkabilisimsistemleri/"
          class="text-white ml-1"
        >
          <feather-icon icon="InstagramIcon" />
        </a>
      </div>
      <div class="card-body p-0">
        <div class="instagram">
          <VueSlickCarousel
            v-bind="datasource.slick"
            class="instagram-banner-content"
          >
            <div
              class="instagram-banner instredirect"
              v-for="item in datasource.ig"
              :key="item"
            >
              <a
                href="https://www.instagram.com/tkabilisimsistemleri/"
                target="_blank"
              >
                <img :src="item" />
              </a>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import api from "@/service/api";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "panel-device-request",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      datasource: {
        slick: {
          dots: true,
          autoplay: true,
          autoplaySpeed: 6000,
          arrows: true,
          infinite: true,
          speed: 300,
          variableWidth: !0,
          centerPadding: "0",
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          /*
          prevArrow:
            '<button type="button" class="slick-prev" aria-label="Previous"><i class="fas fa-angle-left"></i><</button>',
          nextArrow:
            '<button type="button" class="slick-next" aria-label="Next"><i class="fas fa-angle-right"></i>></button>',
            */
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
        ig: [],
      },
    };
  },
  methods: {
    getIG() {
      api.getIG().then((q) => {
        this.datasource.ig = q;
      });
    },
  },
  computed: {
    ...mapState({
      devicefind: (state) => state.devicefind,
    }),
  },
  created() {
    this.getIG();
  },
};
</script>

<style lang="scss" scoped>
.mtn1 {
  margin-top: -0.5rem;
}

.igcard {
  margin-top: -2.5rem;
  .card-header
  {
    background-color:#808080
  }
}

@media (max-width: 991px) {
  .instagram-banner-content {
    /* display: flex;
      flex-wrap: nowrap;
      */
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  .instagram-banner-content .instagram-banner {
    width: 40vw;
    flex: 0 0 40vw;
  }
}

@media (max-width: 767px) {
  .instagram-banner-content .instagram-banner {
    width: 66vw;
    flex: 0 0 66vw;
  }
}

@media (min-width: 992px) {
  .instagram-banner-content {
    height: 0;
    overflow: hidden;
  }

  .instagram-banner-content.slick-slider {
    height: auto;
    overflow: visible;
  }
}

.instagram-banner-content > .slick-arrow {
  width: 4rem;
  height: 4rem;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: transparent;
}

.instagram-banner-content > .slick-arrow > i {
  display: none;
}

.instagram-banner-content > .slick-arrow.slick-prev {
  left: 10%;
  background-image: url(https://tkabilisim.com.tr/assets/images/instagram-left.svg);
  border: none;
}

@media (max-width: 991px) {
  .instagram-banner-content > .slick-arrow.slick-prev {
    left: 4rem;
  }
}

.instagram-banner-content > .slick-arrow.slick-next {
  right: 10%;

  border: none;
}

@media (max-width: 991px) {
  .instagram-banner-content > .slick-arrow.slick-next {
    right: 4rem;
  }
}

.instagram-banner-content > .slick-arrow.slick-disabled {
  opacity: 0.6;
}

.instagram-banner-content .slick-list {
  overflow: visible;
}

.instagram-banner-content .instagram-banner {
  position: relative;
}

.instagram-banner-content .slick-slide a {
  position: relative;
  display: block;
  width: 200px;
  height: 100%;
}

@media (max-width: 991px) {
  .instagram-banner-content .slick-slide a {
    height: 50%;
  }
}

.instagram-banner-content .slick-slide a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100%;
  opacity: 0.68;
  background-color: #000;
  transition: all 0.2s ease-in-out;
}

.instagram-banner-content .slick-slide.slick-active a:after {
  opacity: 0;
  visibility: hidden;
}

.instagram-banner-content .slick-slide.slick-active:before {
  position: absolute;
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  top: 45%;
  left: 45%;
  width: 3.5rem;
  height: 3.5rem;
  background: url(//st3.myideasoft.com/shop/bl/92/themes/selftpl_60389347ea866/assets/images/icon-insta.svg?revision=7.2.1.0-1-1650350816)
    no-repeat;
  z-index: 55;
}

.instagram-banner-content .slick-slide:hover.slick-active::before {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 991px) {
  .instagram-banner-content .slick-slide.slick-active a:after {
    opacity: 0.68;
    visibility: visible;
  }

  .instagram-banner-content .slick-slide.slick-center a:after {
    opacity: 0;
    visibility: hidden;
  }
}

.instagram-banner-content .slick-slide a {
  background-color: #000;
  position: relative;
  display: block;
  width: 100%;
  height: 260px;
}

.instagram-banner-content .slick-slide a::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
  visibility: visible;
  width: 100%;
  height: 100%;
  opacity: 0.68;
  background-color: rgb(0, 0, 0);
  transition: all 0.2s ease-in-out 0s;
}

.slick-slide img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.slick-list {
  background-color: blue !important;
}
.slick-track.slick-center {
  height: 10rem !important;
  background-color: orange;
}

/*
.instagram-banner a
{
    position: relative;
    display: block;
    width: 200px;
    height: 100%
}
.slick-slide img
{
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
*/
</style>