<template>
  <div class="headeritem" style="width: 100%">
    <div class="navbar-container shadow bg-white">
      <div class="logoitem">
        <div
          class="container d-flex align-items-center justify-content-between"
        >
          <img
            class="logo1"
            src="@/assets/images/logo/logo-and-year.svg"
            width="290"
          />
          <img
            class="logomobile d-none"
            src="@/assets/images/logo/logo.svg"
            width="290"
          />
          <img
            class="logo2"
            src="@/assets/images/logo/premium.svg"
            width="320"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style scoped lang="scss">
.secondaryitem {
  background-color: #000;
  border-bottom:1px solid rgb(52, 52, 52);
  display: flex;
  img {
    height: 2rem;
  }
}
</style>
